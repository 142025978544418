export const DEV = [
  {
    id: 12,
    title: "⚠️ Assembly system",
    description:
      "Connected Tech Freelance Backend Developer, Assembly system, An web-application for manage assembly process.",
    coverImg: "./assets/images/projectImg/assembly.png",
    tools: [
      "Golang",
      "MongoDB",
      "Figma",
      "Postman",
      "Jira",
      "Gitlab",
      "Plane",
      "Socket",
    ],
    info: {
      res: [
        "Designed responsive web application UX-UI according to customer requirements.",
        "Implemented scrum methodology.",
        "Designed system workflow, ER diagram, and API Spec of the project.",
        "Developed and maintained database schema using MongoDB.",
        "Developed backend system using Golang.",
        "Collaborated with Frontend developers to integrate web services APIs.",
        "Analyzed errors and implemented solutions.",
        "Integrated system with IoT devices.",
        "Built test cases and tested the web application and IoT devices.",
        "Deployed and managed the web application to live production environment using PM2.",
      ],
    },
  },
  {
    id: 13,
    title: "📘 JPC discord bot Guidebook",
    description:
      "Guidebook for discord bot session (Junior Programmer Camp 16), an event for high school students who interested in programming.This project is for gaining hands-on experience in using discord bot.",
    link: ["https://bookstack.cscms.me/books/discord-bot"],
    coverImg: "./assets/images/projectImg/jpc.png",
    tools: ["discord.py", "Python"],
    info: {
      res: [
        "Worked as mentor of discord bot session.",
        "Written guidebook for discord bot session.",
        "Integrated discord bot with iot session.",
        "Collaborated with team to designed discord bot session.",
      ],
    },
  },
  {
    id: 99,
    title: "🐜 ModLifes",
    description:
      "CS22 Integrated project I, Application for KMUTT's student including 20 features such as announcement, notification, chat, ToDoList, Airdrop, Dating, group, Q&A, Restaurant, Shop, ShopReview, Shortlink, ShortNote, etc.",
    link: ["https://github.com/CSSIT22/studentlife"],
    coverImg: "./assets/images/projectImg/modlifes/mod.png",
    tools: [
      "React",
      "TypeScript",
      "chakra-UI",
      "Figma",
      "PrismaORM",
      "Axios",
      "SocketIO",
      "NodeMailer",
    ],
    info: {
      res: [
        "Worked as a team leader of notification module.",
        "Collaborated with 60+ students.",
        "Implemented scrum methodology.",
        "Developed frontend system using React.js.",
        "Developed backend system using Typescript.",
        "Designed ER Diagram, BPMN Diagram, and workflow of module.",
        "Designed responsive web application UX-UI.",
        "Integrated notification module with 10 other modules.",
        "Developed real-time notification using socket.io.",
        "Implemented email notification using Node Mailer.",
        "Implemented CRUD operation using Prisma.",
      ],
    },
  },
  {
    id: 1,
    title: "🏘️ Moddormmy",
    description:
      "CSC234 User-Centered Mobile App. Dev. & CSC231 Agile Software Engineering, Application for KMUTT's student to find dormitory.",
    link: [
      "https://github.com/KNattawat89/ModDormy",
      "https://github.com/KNattawat89/Moddormy_backend",
    ],
    coverImg: "./assets/images/projectImg/moddormmy/ui2.png",
    tools: ["Flutter", "Golang", "Fiber", "Gorm", "Firebase", "Mysql", "Figma"],
    info: {
      res: [
        "Designed UX-UI of Moddormmy application, application for search and booking dormitory for KMUTT students.",
        "Implemented scrum methodology.",
        "Developed frontend system using Flutter.",
        "Developed backend system using Golang.",
        "Implemented CRUD API using Go Fiber and GORM.",
        "Built test cases and tested the application.",
      ],
    },
  },
  {
    id: 2,
    title: "✨ Gift2Grow",
    description:
      "CS22 Integrated project II, Application for donate education items to children in need.",
    link: [
      "https://github.com/nichakontae/gift2grow",
      "https://github.com/nichakontae/gift2grow_backend",
    ],
    coverImg: "./assets/images/projectImg/gift2grow/ui2.png",
    tools: [
      "Flutter",
      "Dart",
      "Firebase",
      "Fiber",
      "Gorm",
      "mySql",
      "Figma",
      "Golang",
    ],
    info: {
      res: [
        "Designed concept and defined scope of the application.",
        "Implemented scrum methodology.",
        "Designed UX-UI of Gift2Grow application.",
        "Designed ER Diagram and System workflow.",
        "Developed and maintained database schema using MySQL.",
        "Developed frontend system using Flutter.",
        "Developed backend system using Golang.",
        "Implemented CRUD API using Go Fiber and GORM.",
        "Implemented real-time push notification and in-app notification using Firebase Cloud Messaging (FCM).",
      ],
    },
  },
  {
    id: 10,
    title: "🍒 Web-portfolio",
    description: "Personal website to show all of my projects and skills",
    link: ["https://github.com/Ryyyyyy12/ryyyyyy"],
    coverImg: "./assets/images/projectImg/webport.png",
    tools: [
      "React",
      "TypeScript",
      "Figma",
      "tailwindcss",
      "Bootstrap",
      "ReactStrap",
      "MUI",
      "AOS",
      "AXIOS",
      "MongoDB",
    ],
    info: {
      res: [
        "Designed responsive website UX-UI.",
        "Developed frontend using React.js.",
      ],
    },
  },
  {
    id: 11,
    title: "💵 AI THB-Currency Counter",
    description:
      "CSC340 Artificial Intelligence,THB Currency Counter,An web-application for blind which use to detect Thai banknotes and coins by trained AI model, calculate the total amount of money and read it out to the users.",
    link: ["https://github.com/Jakrapat-M/THB_AI_Counter"],
    coverImg: "./assets/images/projectImg/thb3.png",
    tools: [
      "YoloV8",
      "React",
      "Python",
      "Flask",
      "Roboflow",
      "OpenCV",
      "Kaggle",
    ],
    info: {
      res: [
        "Designed responsive web application UX-UI.",
        "Designed system workflow.",
        "Developed THB Currency Counter system using YoloV8 for object detection.",
        "Collected and preprocessed dataset using Roboflow.",
        "Trained and Evaluated AI performance.",
        "Developed frontend using React.js.",
        "Developed backend using Python.",
        "Deployed web-application on live production using Docker.",
      ],
    },
  },
  {
    id: 14,
    title: "Kaggle-Predict Store Sales",
    description:
      "345 Data Science, Predict Store Sales on Kaggle, An data science project for predict store sales using KNN , Linear regression algorithm.",
    link: [],
    coverImg: "./assets/images/projectImg/kaggle.png",
    tools: [
      "Python",
      "Pandas",
      "Numpy",
      "Scikit-learn",
      "Seaborn",
      "Matplotlib",
    ],
    info: {
      res: [
        "Participated in Kaggle-predict store sales competition as a team of 4, resulting in rank 104th place on global leaderboard.",
        "Understood data and visualized data.",
        "Preprocessed data and handled missing value and outlier.",
        "Implemented KNN and Linear regression algorithm.",
        "Evaluated model performance",
        "trained a model to predict store sales using Python.",
      ],
    },
  },
  {
    id: 3,
    title: "🤖 DiscordBot",
    description:
      "Discord bot for real-time announcement grade from KMUTT's system via discord bot DM.",
    link: ["https://github.com/Ryyyyyy12/discordBot"],
    coverImg: "./assets/images/projectImg/bot/announce.png",
    tools: ["Golang"],
  },
  {
    id: 5,
    title: "🛵 Lineman Clone",
    description:
      "Flutter workshop, Frontend Lineman Clone. This project is for gaining hands-on experience in using Flutter.",
    link: ["https://github.com/Ryyyyyy12/flutter-clone-lineman"],
    coverImg: "./assets/images/projectImg/lmclone.png",
    tools: ["Flutter", "Dart"],
  },
  {
    id: 4,
    title: "📸 Instagram Clone",
    description:
      "Flutter workshop, Frontend Instagram Clone. This project is for gaining hands-on experience in using Flutter.",
    link: ["https://github.com/Ryyyyyy12/flutter-clone-instagram"],
    coverImg: "./assets/images/projectImg/igclone.png",
    tools: ["Flutter", "Dart"],
  },
  {
    id: 6,
    title: "🎧 Spotify Clone",
    description:
      "Frontend Spotify Clone. This project is for gaining hands-on experience in website development.",
    link: ["https://github.com/Ryyyyyy12/react-clone-spotify"],
    coverImg: "./assets/images/projectImg/spotifyclone.png",
    tools: ["React", "JavaScript", "Mui", "HTML", "CSS"],
  },
];
