export const ACTIVITIES = [
  {
    id: 3,
    title: "Junior Programmer Camp 16 (JPC16),mentor ( academic )",
  },
  {
    id: 2,
    title: "Google Developer Student Club (GDSC), KMUTT Core Team 2023",
  },
  {
    id: 6,
    title:
      "Malong,event by collaboration of 7 universities (CU, TU, KU, KKU, KMUTT, KMITL, MU) , welfare staff",
  },
  {
    id: 1,
    title: "SIT Open-house 2023 , PR staff",
  },
  {
    id: 0,
    title: "SIT hackathon 2023 , live-stream staff",
  },
  {
    id: 4,
    title: "CS Firstdate 2022 , welfare staff",
  },
  {
    id: 5,
    title: "IOT workshop, participated in fundamental IOT workshop.",
  },
];
