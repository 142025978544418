import React, {useEffect} from 'react';
import {useTheme} from "@mui/material/styles";
import {ACTIVITIES} from "../shared/activities";
import ReactGA from "react-ga4";

const Experiences = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname,
            title: "Activities",
        });
    }, []);

    const theme = useTheme();
    return (
        <div
            className={`text-base font-thin leading-relaxed container ${
                theme.palette.mode === "dark" ? "text-white" : "text-black"
            }`}
            data-aos="fade-down"
        >
            <h3 className="p-5 text-md-start text-center "> 🌻 Activities </h3>
            <div className="md:px-5 mx-5">
            {ACTIVITIES.map((activity) => (
                <ul className="list-disc" data-aos="fade-up" data-aos-offset="300">
                    <li>
                        <p className="md:indent-8"> {activity.title}</p>
                    </li>
                </ul>
            ))}
            </div>
        </div>
    );
};

export default Experiences;