export const LANGUAGES = [
  {
    id: 0,
    name: "java",
    logo: "https://cdn.icon-icons.com/icons2/2415/PNG/512/java_original_wordmark_logo_icon_146459.png",
  },
  {
    id: 1,
    name: "html",
    logo: "https://cdn-icons-png.flaticon.com/512/1532/1532556.png",
  },
  {
    id: 2,
    name: "css",
    logo: "https://cdn-icons-png.flaticon.com/512/5968/5968242.png",
  },
  {
    id: 3,
    name: "javascript",
    logo: "https://cdn-icons-png.flaticon.com/512/5968/5968292.png",
  },
  {
    id: 4,
    name: "typescript",
    logo: "https://cdn-icons-png.flaticon.com/512/5968/5968381.png",
  },
  { id: 5, name: "python", logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/800px-Python-logo-notext.svg.png" },
  // { id: 6, name: "C++", logo: "./assets/images/c++.png" },
  {
    id: 7,
    name: "dart",
    logo: "https://upload.wikimedia.org/wikipedia/commons/7/7e/Dart-logo.png",
  },
  {
    id: 8,
    name: "Golang",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Go_Logo_Blue.svg/1200px-Go_Logo_Blue.svg.png",
  },
];

export const TOOLS = [
  {
    id: 0,
    name: "figma",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Figma-logo.svg/1667px-Figma-logo.svg.png",
  },
  { id: 1, name: "git", logo: "./assets/images/git.png" },
  { id: 2, name: "github", logo: "./assets/images/github.png" },
  // { id: 3, name: "Procreate", logo: "./assets/images/procreate.jfif" },
  // { id: 4, name: "Photoshop", logo: "./assets/images/photoshop.png" },
  // { id: 5, name: "PremierePro", logo: "./assets/images/premierepro.png" },
  {
    id: 6,
    name: "Node.js",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/2560px-Node.js_logo.svg.png",
  },
  {
    id: 7,
    name: "Firebase",
    logo: "https://www.4xtreme.com/wp-content/uploads/2020/11/touchicon-180.png",
  },
  {
    id: 8,
    name: "VScode",
    logo: "https://images-eds-ssl.xboxlive.com/image?url=Q_rwcVSTCIytJ0KOzcjWTYtI_MIrVq4WfN7M.qN7gV3ayNiQeJK6Uxg366DH3bnRmVWMFBWWyXonVyp6x0RYE1elb_jkQQQH7FwsNBBqQO4iFrOIwXtaGkMjmrISfBfgMsCEGwIBPArmzCSVWYx1zA--&format=source",
  },
  {
    id: 9,
    name: "DataGrip",
    logo: "https://resources.jetbrains.com/storage/products/datagrip/img/meta/datagrip_logo_300x300.png",
  },
  {
    id: 10,
    name: "IntelliJ",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/IntelliJ_IDEA_Icon.svg/1200px-IntelliJ_IDEA_Icon.svg.png",
  },
  {
    id: 11,
    name: "Postman",
    logo: "https://static-00.iconduck.com/assets.00/postman-icon-497x512-beb7sy75.png",
  },
  {
    id: 12,
    name: "AndroidStudio",
    logo: "https://2.bp.blogspot.com/-tzm1twY_ENM/XlCRuI0ZkRI/AAAAAAAAOso/BmNOUANXWxwc5vwslNw3WpjrDlgs9PuwQCLcBGAsYHQ/s1600/pasted%2Bimage%2B0.png",
  },
  {
    id: 13,
    name: "Cloudflare",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Cloudflare_Logo.png/480px-Cloudflare_Logo.png",
  },
  {
    id: 14,
    name: "MySQL",
    logo: "https://1000logos.net/wp-content/uploads/2020/08/MySQL-Logo.png",
  },
  {
    id: 15,
    name: "terminus",
    logo: "https://assets-global.website-files.com/5c7036349b5477bf13f828cf/6369e9891c741772f66eef8d_Brand%20Logo%20Icon.svg",
  },
  {
    id: 16,
    name: "Docker",
    logo: "https://www.docker.com/wp-content/uploads/2022/03/vertical-logo-monochromatic.png",
  },
  {
    id: 17,
    logo: "https://miro.medium.com/v2/resize:fit:512/1*doAg1_fMQKWFoub-6gwUiQ.png",
    name: "MongoDB",
  },
  {
    id: 18,
    logo: "https://assets-global.website-files.com/5f6bc60e665f54545a1e52a5/6374c97de94f946ff2b9e7ad_xIWWM_a-_400x400.jpg",
    name: "Roboflow",
  },
  {
    id: 19,
    logo: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/189_Kaggle_logo_logos-512.png",
    name: "Kaggle",
  },
];

export const FRAMEWORKS = [
  {
    id: 0,
    name: "react.js",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png",
  },
  {
    id: 1,
    name: "bootstrap",
    logo: "https://cdn-icons-png.flaticon.com/512/5968/5968672.png",
  },
  {
    id: 2,
    name: "flutter",
    logo: "https://storage.googleapis.com/cms-storage-bucket/a9d6ce81aee44ae017ee.png",
  },
  { id: 3, name: "mui", logo: "https://v4.material-ui.com/static/logo.png" },
  {
    id: 4,
    name: "tailwind",
    logo: "https://static-00.iconduck.com/assets.00/tailwind-css-icon-512x307-1v56l8ed.png",
  },
  {
    id: 5,
    name: "ChakraUI",
    logo: "https://raw.githubusercontent.com/chakra-ui/chakra-ui/main/media/logo-colored@2x.png?raw=true",
  },
  {
    id: 6,
    name: "ReactStrap",
    logo: "https://avatars.githubusercontent.com/u/6853419?v=4&s=400",
  },
  {
    id: 7,
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Axios_%28computer_library%29_logo.svg/1200px-Axios_%28computer_library%29_logo.svg.png",
    name: "Axios",
  },
];
