import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Divider, Grid } from "@mui/material";
import ReactGA from "react-ga4";

const Experiences = () => {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Experiences",
    });
  }, []);
  const theme = useTheme();
  return (
    <div
      className={`text-base font-thin leading-relaxed container ${
        theme.palette.mode === "dark" ? "text-white" : "text-black"
      }`}
      data-aos="fade-down"
    >
      <h3 className="p-5 text-md-start text-center "> 👩‍💻 Work Experiences </h3>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        padding={5}
      >
        <Grid item xs={12} md={3}>
          <p className="text-center"> Jun 2024 - Aug 2024</p>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          className={theme.palette.mode === "dark" ? "bg-white" : "bg-black"}
        ></Divider>
        <Grid item xs={12} md={7}>
          <p className="p-2 ml-5">
            TokenX,
            <span className="text-orange-600"> FullStack Developer Intern</span>
          </p>
        </Grid>
        <Grid item xs={12} md={3}>
          <p className="text-center"> Jan 2024 - Jun 2024</p>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          className={theme.palette.mode === "dark" ? "bg-white" : "bg-black"}
        ></Divider>
        <Grid item xs={12} md={7}>
          <p className="p-2 ml-5">
            Connected Tech,
            <span className="text-orange-600"> FullStack Developer</span>
          </p>
        </Grid>
        <Grid item xs={12} md={3}>
          <p className="text-center"> Sep 2023 - Dec 2023</p>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          className={theme.palette.mode === "dark" ? "bg-white" : "bg-black"}
        ></Divider>
        <Grid item xs={12} md={7}>
          <p className="p-2 ml-5">
            Connected Tech,
            <span className="text-orange-600"> Backend Developer</span>
          </p>
        </Grid>
      </Grid>
    </div>
  );
};

export default Experiences;
